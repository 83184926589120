import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Modal, Form, Col, Row, Checkbox, Select, Spin, message, Input, Tabs } from 'antd';

import SelectWithResultingList from '../components/SelectWithResultingList/SelectWithResultingList';
import Button from '../../../components/Button/Button';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';
import AppointmentTable from '../../../components/AppointmentTable/AppointmentTable';

import useLocale from '../../../hooks/useLocale';
import useDebounce from '../../../hooks/useDebounce';
import useMenu from '../../../hooks/useMenu';

import { LANGUAGES_QUERY, ROLES_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { IdentityService } from '../../../shared/api/IdentityService';
import { ClientService } from '../../../shared/api/ClientService';
import { PdsRoleClientAPI, PdsUserClientAPI } from '../../../utils/identityServiceApi';
import { AuthorizeService } from '../../../components/Auth/AuthorizeService';
import API from '../../../utils/api';

import './UserManagement.scss';
import genericMessage from '../../../utils/genericMessage';
import { argumentifyListUsersSearchCriteria } from '../../ApplicationOverviewPage/ApplicationOverviewContent/AdminInfo/General/utils';
import FormInput from '../../../components/Forms/FormInput/FormInput';
import WeeklySchedule from '../../UserProfile/Availability/WeeklyAvailability/WeeklySchedule/WeeklySchedule';
import Overrides from '../../UserProfile/Availability/WeeklyAvailability/Overrides/Overrides';
import { BULK_UPDATE_FEATUREFLAG } from '../../../components/FeatureFlag';

interface IProps {
  id?: string;
  onOk: () => void;
  onCancel: () => void;
}

const UserEditor = ({ id, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<IdentityService.CreatePdsUserInput>();
  const { Option } = Select;
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { setIsSuperAdmin, setIsSupervisorAllocation } = useMenu();

  const [users, setUsers] = useState<IdentityService.AdUserDto[]>();
  const [selectedUser, setSelectedUser] = useState<IdentityService.AdUserDto>();
  const [teams, setTeams] = useState<ClientService.LookupDto[]>();
  const [teamTypes, setTeamTypes] = useState<ClientService.LookupDto[]>();
  const [estateAdministrators, setEstateAdministrators] = useState<IdentityService.PdsUserListItem[]>([]);
  const [trustees, setTrustees] = useState<IdentityService.PdsUserListItem[]>([]);
  const [agents, setAgents] = useState<IdentityService.PdsUserListItem[]>([]);
  const [hasAgentRole, setHasAgentRole] = useState(false);
  const [noRoleSelected, hasRole] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState<ClientService.ViewAppointmentsResultDto[]>();

  const [isSaving, setSaving] = useState<boolean>(false);
  const [isOverSaving, setOverSaving] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('1');

  const [editDone, setEditDone] = useState<boolean>(false);
  const [scheduleDone, setScheduleDone] = useState<boolean>(false);
  const [overridesDone, setOverridesDone] = useState<boolean>(false);

  const [disabledForm, setDisabledForm] = useState<boolean>(true);
  const [disabledFieldsNames, setDisabledFieldsNames] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [activeOffices, setActiveOffices] = useState<ClientService.OfficeLocationDto[]>();
  const [disabledOffice, setDisabledOffice] = useState<ClientService.LookupDto>();

  const value = useDebounce(searchTerm);
  const user = AuthorizeService.getCurrentUserInfo();
  const [isEstateAdminForFiles, setIsEstateAdminForFiles] = useState(false);
  const [isTrusteeForFiles, setIsTrusteeForFiles] = useState(false);
  const [isCourtTrusteeForFiles, setIsCourtTrusteeForFiles] = useState(false);
  const [hasActions, setHasActions] = useState<boolean>(false);
  const [hasAppointments, setHasAppointments] = useState<boolean>(false);
  const [hasTasks, setHasTasks] = useState<boolean>(false);

  const [isActive, setIsActive] = useState(false);

  const { data: languages } = useQuery([LANGUAGES_QUERY], () => API.listLanguages(), USE_QUERY_OPTIONS);
  const { data: roles } = useQuery([ROLES_QUERY], () => PdsRoleClientAPI.listRoles(), USE_QUERY_OPTIONS);

  const requestOffices = useCallback(async () => {
    const response = await API.listOfficeLocations(false);
    if (response) setActiveOffices(response);
  }, []);

  const requestUsers = useCallback(
    async (value?: string) => {
      const response = await PdsUserClientAPI.searchAdUsers(value);

      if (response) {
        setUsers(response);

        if (response?.length > 1) {
          setDisabledForm(true);
          form.resetFields();
        }
      }
    },
    [form]
  );

  const changeActiveTab = useCallback(
    (key) => {
      setActiveTab(key);
    },
    [setActiveTab]
  );

  const setTabToSchedule = useCallback(() => {
    setActiveTab('2');
  }, [setActiveTab]);

  const switchTabs = useCallback(async () => {
    setEditDone(false);
    setScheduleDone(false);
    setOverridesDone(false);

    await form.validateFields().catch((err) => {
      setEditDone(false);
      setActiveTab('1');
    });
  }, [setActiveTab, setEditDone, setScheduleDone, setOverridesDone, form]);

  const requestTeamsByTeamTypes = useCallback(
    async (teamTypeIds?: string[], teamIds?: string[]) => {
      if (!teamTypeIds) return;

      setLoading(true);
      let teams: ClientService.LookupDto[] = [];
      for (const id of teamTypeIds) {
        const response = await API.listTeams(id).catch(() => setLoading(false));
        if (response) {
          teams = [...teams, ...response];
        }
      }
      setLoading(false);

      setTeams(teams);
      form.setFieldsValue({ teamIds: teamIds?.filter((teamId) => teams?.find((team) => team.id === teamId)) });
    },
    [form]
  );

  const checkHasAgentRole = useCallback(
    (roleIds?: string[]) => {
      if (roleIds) {
        if (roleIds.length == 0) hasRole(false);
        else hasRole(true);
        const selectedRoles = roleIds.map((id) => roles?.find((role) => role.id === id)?.name);
        const isAgent = selectedRoles.some((s) => s === 'Agent');
        setHasAgentRole(isAgent);
      } else {
        hasRole(false);
        setHasAgentRole(false);
      }
    },
    [roles]
  );

  const requestTeamTypesByRoles = useCallback(
    async (roleIds?: string[], teamTypeIds?: string[], teamIds?: string[]) => {
      checkHasAgentRole(roleIds);

      if (!roleIds || !roleIds?.length) {
        setTeamTypes(undefined);
        setTeams(undefined);
        form.setFieldsValue({
          teamTypeIds: undefined,
          teamIds: undefined,
        });
        return;
      }

      setLoading(true);
      const teamTypes = await API.listTeamTypes(roleIds).catch(() => setLoading(false));
      setLoading(false);

      if (teamTypes) setTeamTypes(teamTypes);
      const filteredTeamTypeIds = teamTypeIds?.filter((teamTypeId) =>
        teamTypes?.find((item) => item.id === teamTypeId)
      );
      form.setFieldsValue({ teamTypeIds: filteredTeamTypeIds });

      await requestTeamsByTeamTypes(filteredTeamTypeIds, teamIds);
    },
    [checkHasAgentRole, form, requestTeamsByTeamTypes]
  );

  const validateForm = useCallback(
    (userData?: IdentityService.PdsUserDto) => {
      if (!BULK_UPDATE_FEATUREFLAG) {
        if (userData?.hasActions) message.warning(t.USER_ACCOUNT_PENDING_ACTIONS_ERROR, 5);
        if (userData?.hasTasks) message.warning(t.USER_ACCOUNT_PENDING_TASKS_ERROR, 5);
        if (userData?.hasAppointments) message.warning(t.USER_ACCOUNT_PENDING_APPOINTMENTS_ERROR, 5);
      }

      return (
        userData?.hasActions ||
        userData?.hasTasks ||
        userData?.hasAppointments ||
        userData?.hasAgentFiles ||
        userData?.hasTrusteeCourts ||
        userData?.hasEstateAdministratorFiles
      );
    },
    [
      t.USER_ACCOUNT_AGENT_FILES_ERROR,
      t.USER_ACCOUNT_ESTATE_ADMIN_FILES_ERROR,
      t.USER_ACCOUNT_PENDING_ACTIONS_ERROR,
      t.USER_ACCOUNT_PENDING_TASKS_ERROR,
      t.USER_ACCOUNT_PENDING_APPOINTMENTS_ERROR,
      t.USER_ACCOUNT_TRUSTEE_COURTS_ERROR,
    ]
  );

  const disableFieldsOnValidationFailed = useCallback((userData?: IdentityService.PdsUserDto) => {
    let disabledFields = [];

    if (
      userData?.hasActions ||
      userData?.hasAppointments ||
      userData?.hasAgentFiles ||
      userData?.hasTrusteeCourts ||
      userData?.hasEstateAdministratorFiles
    ) {
      disabledFields.push(...['roleIds', 'teamTypeIds', 'teamIds']);
    }

    if (userData?.hasActions || userData?.hasTasks || userData?.hasAppointments) {
      disabledFields.push('isActive');
    }

    if (userData?.hasAppointments) {
      disabledFields.push('preferredLanguageId');
    } else if (BULK_UPDATE_FEATUREFLAG) {
      disabledFields.push('appointmentsUser');
    }

    if (BULK_UPDATE_FEATUREFLAG && !userData?.hasTasks) {
      disabledFields.push('taskUser');
    }

    if (BULK_UPDATE_FEATUREFLAG && !userData?.hasActions) {
      disabledFields.push('actionsUser');
    }

    setDisabledFieldsNames(disabledFields);
  }, []);

  const handleDisabledOfficeId = useCallback(
    async (officeLocationId?: string) => {
      const activeOfficeDto = activeOffices?.find((item) => item?.id === officeLocationId);
      if (!activeOfficeDto) {
        const allOffices = await API.listOfficeLocations(true);

        if (allOffices) {
          const disabledOfficeDto = allOffices?.find((item) => item?.id === officeLocationId);
          setDisabledOffice(disabledOfficeDto);
        }
      }
    },
    [activeOffices]
  );

  const requestUser = useCallback(
    async (userId?: string) => {
      setLoading(true);
      const response = await PdsUserClientAPI.getUser(userId).catch(() => setLoading(false));

      if (id) {
        setActiveTab('2');
        setActiveTab('1');
      }
      setLoading(false);

      if (response) {
        setDisabledForm(false);

        form.setFieldsValue(response);
        handleDisabledOfficeId(response?.officeLocationId);
        await requestTeamTypesByRoles(response?.roleIds, response?.teamTypeIds, response?.teamIds);
        setSelectedUser(response);

        setIsActive(response?.isActive ?? false);
        setIsEstateAdminForFiles(response?.isEstateAdminOfFiles ?? false);
        setIsTrusteeForFiles(response?.isTrusteeOfFiles ?? false);
        setIsCourtTrusteeForFiles(response?.hasTrusteeCourts ?? false);

        if (BULK_UPDATE_FEATUREFLAG) {
          setHasActions(response?.hasActions ?? false);
          setHasAppointments(response?.hasAppointments ?? false);
          setHasTasks(response?.hasTasks ?? false);
        }

        if (validateForm(response)) {
          disableFieldsOnValidationFailed(response);
        }
      }
    },
    [form, handleDisabledOfficeId, requestTeamTypesByRoles, validateForm, disableFieldsOnValidationFailed]
  );

  const requestCreateUser = useCallback(
    async (values) => {
      setLoading(true);
      const response = await PdsUserClientAPI.createUser({ adUserId: selectedUser?.adUserId, ...values }).catch(
        (error) => {
          setLoading(false);
          genericMessage.error({}, error?.message);
        }
      );
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (!id) onOk();
        else {
          setEditDone(true);
        }
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [onOk, selectedUser?.adUserId, setEditDone]
  );

  const requestRoles = useCallback(
    async (email?: string) => {
      const isSuperAdmin = await PdsUserClientAPI.isSuperAdmin(email);
      setIsSuperAdmin(isSuperAdmin || false);

      const isSupervisorAllocation = await PdsUserClientAPI.isSupervisorAllocation(email);
      setIsSupervisorAllocation(isSupervisorAllocation || false);
    },
    [setIsSuperAdmin, setIsSupervisorAllocation]
  );

  const requestUpdateUser = useCallback(
    async (values) => {
      setLoading(true);
      const response = await PdsUserClientAPI.updateUser({ id, ...values }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (id === user?.profile?.sub) {
          requestRoles(user?.profile?.email);
        }

        if (!id) onOk();
        else {
          setEditDone(true);
        }
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [id, user?.profile?.sub, user?.profile?.email, onOk, requestRoles, setEditDone]
  );

  const hasChanged = useCallback(
    (val) => {
      return val && val !== '' && val !== id;
    },
    [id]
  );

  const cleanGuid = useCallback(
    (val) => {
      if (val && (val === '' || val === id)) {
        return undefined;
      }
      return val;
    },
    [id]
  );

  const requestTransferResponsibilities = useCallback(
    async (values) => {
      setLoading(true);
      let updated = false;

      if (hasChanged(values.taskUser)) {
        await API.assignTasksToAgent(id, values.taskUser);
        updated = true;
      }

      if (hasChanged(values.actionsUser)) {
        await API.assignActionsToAgent(id, values.actionsUser);
        updated = true;
      }

      if (hasChanged(values.estateAdminUser) || hasChanged(values.trusteeUser) || hasChanged(values.courtTrusteeUser)) {
        await API.updateEstatesAndTrustees(
          id,
          cleanGuid(values.estateAdminUser),
          cleanGuid(values.trusteeUser),
          cleanGuid(values.courtTrusteeUser)
        );
        updated = true;
      }

      if (hasChanged(values.appointmentsUser)) {
        await API.assignAppointmentsToAgent(id, values.appointmentsUser);
        updated = true;

        const pendingAppoint = await API.listPendingAppointmentDetailsByAgentId(id);
        if (pendingAppoint && pendingAppoint.length > 0) {
          setPendingAppointments(pendingAppoint);
          setEditDone(false);
          genericMessage.warning(t.PENDING_APPOINTMENTS_NOTICE);
          setActiveTab('4');
        }
      } else {
        setPendingAppointments([]);
      }

      if (updated) {
        const response = await PdsUserClientAPI.getUser(id).catch(() => setLoading(false));
        setIsEstateAdminForFiles(response?.isEstateAdminOfFiles ?? false);
        setIsTrusteeForFiles(response?.isTrusteeOfFiles ?? false);
        setIsCourtTrusteeForFiles(response?.hasTrusteeCourts ?? false);
        setHasActions(response?.hasActions ?? false);
        setHasAppointments(response?.hasAppointments ?? false);
        setHasTasks(response?.hasTasks ?? false);
        form.resetFields([
          'taskUser',
          'actionsUser',
          'estateAdminUser',
          'trusteeUser',
          'courtTrusteeUser',
          'appointmentsUser',
        ]);
      }

      setLoading(false);
    },
    [
      id,
      form,
      t.PENDING_APPOINTMENTS_NOTICE,
      cleanGuid,
      hasChanged,
      setLoading,
      setEditDone,
      setActiveTab,
      setPendingAppointments,
      setIsEstateAdminForFiles,
      setIsTrusteeForFiles,
      setIsCourtTrusteeForFiles,
      setHasActions,
      setHasAppointments,
      setHasTasks,
    ]
  );

  const onFinish = useCallback(
    async (values) => {
      setSaving(false);
      setOverSaving(false);
      setSaving(true);
      if (id) {
        if (BULK_UPDATE_FEATUREFLAG) {
          await requestTransferResponsibilities(values);
        }
        await requestUpdateUser(values);
      } else {
        await requestCreateUser(values);
      }
    },
    [requestCreateUser, requestTransferResponsibilities, requestUpdateUser, setSaving, id]
  );

  const handleValuesChange = useCallback(
    async (value, values) => {
      if (Object.keys(value)?.indexOf('teamTypeIds') >= 0) {
        await requestTeamsByTeamTypes(value?.teamTypeIds, values?.teamIds);
        return;
      }

      if (Object.keys(value)?.indexOf('roleIds') >= 0) {
        await requestTeamTypesByRoles(value?.roleIds, values?.teamTypeIds, values?.teamIds);
        return;
      }

      if (Object.keys(value)?.indexOf('isActive') >= 0) {
        setIsActive(value?.isActive ?? false);
      }
    },
    [requestTeamTypesByRoles, requestTeamsByTeamTypes]
  );

  const sortUsers = useCallback((list: IdentityService.PdsUserListItem[]) => {
    return list
      ?.filter((x) => x.fullName != undefined && x.fullName != null && x.fullName.trim().length > 0)
      .sort((a, b) => ((a.fullName || '').toUpperCase() > (b.fullName || '').toUpperCase() ? 1 : -1));
  }, []);

  const updateEstateAdministrators = useCallback(async () => {
    let estateAdministrators = await PdsUserClientAPI.listUsers(
      ...argumentifyListUsersSearchCriteria({
        roleId: roles?.find((r) => r?.name === 'Estate Administrator')?.id,
        isActive: true,
      })
    );

    if (estateAdministrators) {
      setEstateAdministrators(sortUsers(estateAdministrators?.items || []) || []);
    }
  }, [roles, setEstateAdministrators, sortUsers]);

  const updateTrustees = useCallback(async () => {
    let trustees = await PdsUserClientAPI.listUsers(
      ...argumentifyListUsersSearchCriteria({ roleId: roles?.find((r) => r?.name === 'Trustee')?.id, isActive: true })
    );

    if (trustees) {
      setTrustees(sortUsers(trustees?.items || []) || []);
    }
  }, [roles, setTrustees, sortUsers]);

  const updateAgents = useCallback(async () => {
    let agents = await PdsUserClientAPI.listUsers(
      ...argumentifyListUsersSearchCriteria({ roleId: roles?.find((r) => r?.name === 'Agent')?.id, isActive: true })
    );

    if (agents) {
      setAgents(sortUsers(agents?.items || []) || []);
    }
  }, [roles, setAgents, sortUsers]);

  useEffect(() => {
    if (value?.trim()?.length >= 3) requestUsers(value);
  }, [requestUsers, value]);

  useEffect(() => {
    if (id) requestUser(id);
  }, [requestUser, id]);

  useEffect(() => {
    requestOffices();
  }, [requestOffices]);

  useEffect(() => {
    if (editDone && scheduleDone) {
      setOverSaving(true);
    }
  }, [editDone, scheduleDone, setOverSaving]);

  useEffect(() => {
    if (editDone && scheduleDone && overridesDone) {
      if (BULK_UPDATE_FEATUREFLAG && pendingAppointments && pendingAppointments.length > 0) {
        setActiveTab('4');
      } else {
        genericMessage.success(t.INFORMATION_UPDATED);
        onOk();
      }
    }
  }, [editDone, scheduleDone, overridesDone, pendingAppointments, t.INFORMATION_UPDATED, onOk]);

  useEffect(() => {
    if (isEstateAdminForFiles && estateAdministrators?.length === 0) updateEstateAdministrators();
    if ((isTrusteeForFiles || isCourtTrusteeForFiles) && trustees?.length === 0) updateTrustees();
    if (BULK_UPDATE_FEATUREFLAG && (hasActions || hasAppointments || hasTasks) && agents?.length === 0) updateAgents();
  }, [
    isCourtTrusteeForFiles,
    isEstateAdminForFiles,
    isTrusteeForFiles,
    hasActions,
    hasAppointments,
    hasTasks,
    isActive,
    agents?.length,
    estateAdministrators?.length,
    trustees?.length,
    updateAgents,
    updateEstateAdministrators,
    updateTrustees,
  ]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={id ? t.EDIT_USER : t.ADD_USER}
      width={1080}
      footer={null}
      onCancel={onCancel}
      className="UserManagement__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onValuesChange={handleValuesChange} initialValues={{ isActive: true }}>
          <Row gutter={40}>
            <Col span={12}>
              {!id && (
                <Row gutter={10}>
                  <Col span={23}>
                    <Form.Item label={t.SEARCH_BY_EMAIL_ID} labelCol={{ span: 8 }} labelAlign="left">
                      <Select
                        allowClear
                        showSearch
                        size="large"
                        value={searchTerm}
                        onChange={setSearchTerm}
                        onSearch={setSearchTerm}
                        onSelect={(emailAddress: string) => {
                          const selectedUser = users?.find((item) => item.emailAddress === emailAddress);
                          setSelectedUser(selectedUser);
                          setDisabledForm(false);
                        }}
                        showArrow={false}
                        filterOption={false}
                      >
                        {users?.map((user) => (
                          <Option key={user?.adUserId} value={user?.emailAddress}>
                            {user.emailAddress}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              )}
            </Col>
          </Row>
          {selectedUser && (
            <Tabs activeKey={activeTab} onChange={changeActiveTab} defaultActiveKey={'1'}>
              <Tabs.TabPane tab={t.GENERAL_USER_INFORMATION} key={'1'}>
                <Row gutter={40}>
                  <>
                    <Col span={12} offset={0}>
                      <Form.Item
                        label={t.FIRST_NAME}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        name={'firstName'}
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <Input size={'large'} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t.LAST_NAME}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        name={'lastName'}
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <Input size={'large'} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label={t.EMAIL_ID} labelCol={{ span: 8 }} labelAlign="left">
                        <div>{!disabledForm ? selectedUser?.emailAddress : '-'}</div>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t.PHONE_NUMBER} labelCol={{ span: 8 }} labelAlign="left">
                        <div>{!disabledForm ? selectedUser?.phoneNumber : '-'}</div>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <SelectWithResultingList
                        name="roleIds"
                        data={roles}
                        label={t.ROLE}
                        labelCol={{ span: 8 }}
                        required
                        disabled={disabledForm}
                        canDelete={true}
                      />
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="preferredLanguageId"
                        label={t.PREFERRED_LANGUAGE}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <SelectLookupDto data={languages} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item noStyle shouldUpdate>
                        <SelectWithResultingList
                          name="teamTypeIds"
                          data={teamTypes}
                          label={t.TEAM_TYPE}
                          labelCol={{ span: 8 }}
                          required
                          disabled={disabledForm}
                          canDelete={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label={t.DEFAULT_OFFICE}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        name="officeLocationId"
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <SelectLookupDto
                          data={disabledOffice ? [disabledOffice, ...(activeOffices || [])] : activeOffices}
                          disabled={disabledForm || disabledFieldsNames?.indexOf('officeLocationId') >= 0}
                          showSearch
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <SelectWithResultingList
                        name="teamIds"
                        data={teams}
                        label={t.TEAM}
                        labelCol={{ span: 8 }}
                        required
                        disabled={disabledForm}
                        canDelete={true}
                      />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="isActive"
                        valuePropName="checked"
                        label={t.STATUS}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                      >
                        <Checkbox disabled={disabledForm || disabledFieldsNames?.indexOf('isActive') >= 0} />
                      </Form.Item>
                    </Col>
                    {!BULK_UPDATE_FEATUREFLAG && !isActive && isEstateAdminForFiles && (
                      <Col span={12}>
                        <Form.Item noStyle shouldUpdate>
                          <FormInput
                            type="select"
                            name="estateAdministratorId"
                            label={t.ESTATE_ADMINISTRATOR}
                            labelCol={{ span: 8 }}
                            labelAlign="left"
                            required
                            disabled={disabledForm}
                            optionsList={estateAdministrators?.map((estateAdmin) => ({
                              value: estateAdmin.id,
                              label: estateAdmin?.fullName,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {!BULK_UPDATE_FEATUREFLAG && !isActive && isTrusteeForFiles && (
                      <Col span={12}>
                        <Form.Item noStyle shouldUpdate>
                          <FormInput
                            type="select"
                            name="trusteeId"
                            label={t.TRUSTEE}
                            labelCol={{ span: 8 }}
                            labelAlign="left"
                            required
                            disabled={disabledForm}
                            optionsList={trustees?.map((trustee) => ({
                              value: trustee.id,
                              label: trustee?.fullName,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {!BULK_UPDATE_FEATUREFLAG && !isActive && isCourtTrusteeForFiles && (
                      <Col span={12}>
                        <Form.Item noStyle shouldUpdate>
                          <FormInput
                            type="select"
                            name="courtTrusteeId"
                            label={t.COURT_TRUSTEE}
                            labelCol={{ span: 8 }}
                            labelAlign="left"
                            required
                            disabled={disabledForm}
                            optionsList={trustees?.map((trustee) => ({
                              value: trustee.id,
                              label: trustee?.fullName,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                </Row>
              </Tabs.TabPane>
              {id && (
                <Tabs.TabPane tab={t.AVAILABILITY} key={'2'} forceRender={true}>
                  <Row gutter={100}>
                    <Col span={24}>
                      <WeeklySchedule
                        userId={id}
                        hideSaveButton={true}
                        externalSaveTrigger={isSaving}
                        setTab={setTabToSchedule}
                        scheduleDone={setScheduleDone}
                      />
                    </Col>
                    <Col span={24}>
                      <Overrides
                        userId={id}
                        useCustomAdd={true}
                        externalSaveTrigger={isOverSaving}
                        overridesDone={setOverridesDone}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
              )}
              {BULK_UPDATE_FEATUREFLAG && id && (
                <Tabs.TabPane tab={t.BULK_UPDATE_USER} key={'3'}>
                  <Row gutter={40}>
                    <>
                      <Col span={24} offset={0}>
                        <Form.Item name="taskUser">
                          <FormInput
                            type="select"
                            name="taskUser"
                            label={t.APPLICATION_OVERVIEW_TASKS}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            style={{ marginBottom: 0 }}
                            labelAlign="left"
                            disabled={!hasTasks}
                            noSelectOption={true}
                            placeholder={t.SELECT}
                            optionsList={[
                              ...agents?.map((agent) => ({
                                value: agent.id,
                                label: agent?.fullName,
                              })),
                            ]}
                          />
                          {!hasTasks && <>{t.NO_TASKS_MESSAGE}</>}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="actionsUser">
                          <FormInput
                            type="select"
                            name="actionsUser"
                            label={t.ACTIONS}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            style={{ marginBottom: 0 }}
                            labelAlign="left"
                            disabled={!hasActions}
                            noSelectOption={true}
                            placeholder={t.SELECT}
                            optionsList={[
                              ...agents?.map((agent) => ({
                                value: agent.id,
                                label: agent?.fullName,
                              })),
                            ]}
                          />
                          {!hasActions && <>{t.NO_ACTIONS_MESSAGE}</>}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="appointmentsUser">
                          <FormInput
                            type="select"
                            name="appointmentsUser"
                            label={t.APPLICATION_OVERVIEW_APPOINTMENTS}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            style={{ marginBottom: 0 }}
                            labelAlign="left"
                            disabled={!hasAppointments}
                            noSelectOption={true}
                            placeholder={t.SELECT}
                            optionsList={[
                              ...agents?.map((agent) => ({
                                value: agent.id,
                                label: agent?.fullName,
                              })),
                            ]}
                          />
                          {!hasAppointments && <>{t.NO_APPOINTMENTS_MESSAGE}</>}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="estateAdminUser">
                          <FormInput
                            type="select"
                            name="estateAdminUser"
                            label={t.ESTATE_ADMINISTRATOR}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            style={{ marginBottom: 0 }}
                            labelAlign="left"
                            disabled={!isEstateAdminForFiles}
                            noSelectOption={true}
                            placeholder={t.SELECT}
                            optionsList={[
                              ...estateAdministrators?.map((estateAdmin) => ({
                                value: estateAdmin.id,
                                label: estateAdmin?.fullName,
                              })),
                            ]}
                          />
                          {!isEstateAdminForFiles && <>{t.NO_ESTATE_ADMINS_MESSAGE}</>}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="trusteeUser">
                          <FormInput
                            type="select"
                            name="trusteeUser"
                            label={t.TRUSTEE}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            style={{ marginBottom: 0 }}
                            labelAlign="left"
                            disabled={!isTrusteeForFiles}
                            noSelectOption={true}
                            placeholder={t.SELECT}
                            optionsList={[
                              ...trustees?.map((trustee) => ({
                                value: trustee.id,
                                label: trustee?.fullName,
                              })),
                            ]}
                          />
                          {!isTrusteeForFiles && <>{t.NO_TRUSTEES_MESSAGE}</>}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="courtTrusteeUser">
                          <FormInput
                            type="select"
                            name="courtTrusteeUser"
                            label={t.COURT_TRUSTEE}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 8 }}
                            style={{ marginBottom: 0 }}
                            labelAlign="left"
                            disabled={!isCourtTrusteeForFiles}
                            noSelectOption={true}
                            placeholder={t.SELECT}
                            optionsList={[
                              ...trustees?.map((trustee) => ({
                                value: trustee.id,
                                label: trustee?.fullName,
                              })),
                            ]}
                          />
                          {!isCourtTrusteeForFiles && <>{t.NO_COURT_TRUSTEES_MESSAGE}</>}
                        </Form.Item>
                      </Col>
                    </>
                  </Row>
                </Tabs.TabPane>
              )}
              {BULK_UPDATE_FEATUREFLAG && id && pendingAppointments && pendingAppointments.length > 0 && (
                <Tabs.TabPane tab={t.PENDING_APPOINTMENTS} key={'4'}>
                  <AppointmentTable
                    editable={false}
                    userId={id}
                    showPendingForAgent={true}
                    pendingAppointments={pendingAppointments}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          )}
          <Row gutter={12} justify="end" className="TaskEditor__footer">
            <Col>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" onClick={switchTabs} htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UserEditor;
